import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import AnimationRevealPage from '../components/AnimationRevealPage';
import ReactHtmlParser from 'react-html-parser';

const ComponentName = ({ data }) => {
  const users = data.allPosts.nodes;

  return (
    <AnimationRevealPage>
      <Layout>
        <section className=" md:overflow-x-scroll mt-24 md:mt-10 mb-10">
          <div className="container p-5 py-12 mx-auto md:p-20 ">
            <h1 className="text-4xl font-bold text-center text-purple-700 mt-10 mb-10">
              Nos articles:
            </h1>
            <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
              {users.map((node, i) => (
                <div key={i} className="mt-4">
                  <div className="flex w-full">
                    <div className="relative flex flex-col items-start m-1 transition duration-300 ease-in-out delay-150 transform bg-white shadow-2xl rounded-xl  md:hover:-translate-x-16 md:hover:-translate-y-8">
                      <Link
                        to={`/posts/${node.seo_title.replace(/[^a-zA-Z0-9\u00C0-\u00FF]/g, '-')}`}
                        className=""
                      >
                        <img
                          className="object-cover object-center w-full rounded-t-xl md:h-96 h-96"
                          src={node.url}
                          alt={node.title}
                        />
                      </Link>
                      <div className="px-6 py-8">
                        <span class="text-xs font-medium text-purple-700 uppercase border rounded-full border-purple-700 px-2 py-1">
                          {node.seo_keyword1} | {node.seo_keyword2}
                        </span>
                        <h4 className="mt-4 text-2xl font-semibold text-neutral-600">
                          <span className="">{node.title}</span>
                        </h4>

                        <div className="mt-4 text-base font-normal text-gray-500 leading-relax line-clamp-6">
                          {ReactHtmlParser(node.body.body)}
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-4 ">
                          <Link
                            to={`/posts/${node.seo_title.replace(
                              /[^a-zA-Z0-9\u00C0-\u00FF]/g,
                              '-'
                            )}`}
                            className=""
                          >
                            <button className="text-white items-center justify-center px-4 py-3 bg-purple-700 rounded-full">
                              Lire plus
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    </AnimationRevealPage>
  );
};

export const query = graphql`
  {
    allPosts {
      nodes {
        author
        created_at(formatString: "D MMMM YYYY", locale: "fr")
        id
        postId
        seo_keyword1
        seo_keyword2
        seo_keyword3
        seo_keyword4
        seo_keyword5
        seo_title
        title
        url
        body {
          body
        }
      }
    }
  }
`;

export default ComponentName;
